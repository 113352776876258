import { useTracking } from '@/components/Tracking/useTracking/useTracking'
import useOnScreen from '@/domains/product/legacy/Catalog/hooks'
import { Banner } from '@/generated/contentful/contentful'
import { trackPromotionClicked, trackPromotionViewed, trackingString } from '@/utils/analytics/helpers'
import { isTenant } from '@/utils/config'
import cx from 'classnames'
import { ComponentType, useEffect, useMemo, useRef, useState } from 'react'
import { renderPencilText } from './PencilBanner.adapter'

import stylesTK from './PencilBannerTK.module.scss'
import stylesWW from './PencilBannerWW.module.scss'

function redirectTo(link: string) {
  return window.location.assign(link)
}

const styles = isTenant('ww') ? stylesWW : stylesTK

type PencilBannerProps = Pick<Banner, 'text' | 'backgroundColor' | 'bannerLink' | 'location' | 'page' | 'promotion'>

export const PencilBanner: ComponentType<PencilBannerProps> = ({
  location,
  page,
  text,
  backgroundColor,
  bannerLink,
  promotion,
}) => {
  const [isViewed, setIsViewed] = useState<boolean>(false)

  const bannerRef = useRef<HTMLDivElement | null>(null)

  const trackingData = useTracking()

  const isVisible = useOnScreen(bannerRef)

  const promotionTrackingData = useMemo(
    () => ({
      promotion_id: trackingString(promotion?.sys.id),
      creative: '',
      name: trackingString(promotion?.name as string),
      position: page && location ? trackingString(`${page} ${location} Banner`) : '',
    }),
    [promotion, page, location]
  )

  useEffect(() => {
    const anchor = document.getElementById('pencil-text-link') as HTMLAnchorElement

    if (!anchor) return

    const handleClick = async (e: MouseEvent) => {
      e?.stopImmediatePropagation()
      e?.preventDefault()

      await trackPromotionClicked(
        {
          ...promotionTrackingData,
          ...trackingData,
        },
        () => redirectTo(anchor.href as string)
      )
    }

    anchor.addEventListener('click', handleClick)

    return () => anchor.removeEventListener('click', handleClick)
  }, [promotionTrackingData, trackingData])

  useEffect(() => {
    if (typeof window === 'undefined' || isViewed || !isVisible) return

    trackPromotionViewed({ ...promotionTrackingData, ...trackingData })

    setIsViewed(true)
  }, [isVisible, promotionTrackingData, trackingData, isViewed])

  if (!text) return null

  const bgColor = backgroundColor?.replace('#', 'color-').toLowerCase() || ''

  async function handleBannerClick() {
    if (!bannerLink) return

    await trackPromotionClicked(
      {
        ...promotionTrackingData,
        ...trackingData,
      },
      () => redirectTo(bannerLink)
    )
  }

  return (
    <div
      className={cx(styles.banner, styles[bgColor])}
      ref={bannerRef}
      onClick={handleBannerClick}
      onAuxClick={handleBannerClick}
    >
      {renderPencilText(text)}
    </div>
  )
}
